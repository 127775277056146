@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

main {
  display: block;
}

select,
input,
textarea,
button {
  outline: none;
  margin: 0;
  cursor: pointer;
}

textarea,
button,
input[type=text],
input[type=password],
input[type=submit],
input[type=reset],
input[type=tel],
input[type=number] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: none;
  border: solid 1px #333;
  padding: 3px;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: none;
  border-radius: 0;
}

button {
  padding: 0;
  border: none;
  overflow: visible;
}

textarea {
  resize: none;
}

hr {
  margin: 0;
  padding: 0;
  border: none;
  background: black;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "palt";
          font-feature-settings: "palt";
  overflow-wrap: break-word;
}

.clear,
.clearfix {
  zoom: 100%;
}
.clear:before,
.clearfix:before, .clear:after,
.clearfix:after {
  content: "";
  clear: both;
  height: 0;
  display: block;
  visibility: hidden;
}

.arrow_scroll {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

#top .top_contents .com_list li {
  font-size: 0;
  letter-spacing: 0;
  font-weight: normal;
}

/*
Media Query Mixin
*/
html {
  font-family: YakuHanJP, "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  color: #333;
  font-weight: 400;
  font-size: 62.5%;
}

body {
  font-size: 1em;
}

input,
button,
textarea {
  font-family: YakuHanJP, "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  color: #333;
}

a {
  color: #333;
}

.gothic {
  font-family: YakuHanJP, "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}

.mincho {
  font-family: YakuHanMP, "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

footer .copy {
  font-family: "Lato", sans-serif;
}

footer .copy {
  font-weight: 300;
}

.gr2 {
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  background: -webkit-gradient(linear, left top, right top, from(#9ea7b8), color-stop(51%, #b9c0cb), to(#9ea7b8));
  background: -webkit-linear-gradient(left, #9ea7b8 0%, #b9c0cb 51%, #9ea7b8 100%);
  background: linear-gradient(to right, #9ea7b8 0%, #b9c0cb 51%, #9ea7b8 100%);
  background-size: 200% auto;
}
.gr2:hover {
  background-position: right center;
}

header {
  padding: 45px 0;
  position: relative;
}
header:before {
  content: "";
  width: 0%;
  height: 6px;
  background: #DC2329;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: all 1s ease-out;
  transition: all 1s ease-out;
}
header.show:before {
  width: 100%;
}
header.show h1 {
  opacity: 1;
  -webkit-transform: translate(0);
          transform: translate(0);
}
header h1 {
  width: 449px;
  height: auto;
  margin: auto;
  opacity: 0;
  -webkit-transition: all 1.2s 0.3s ease-out;
  transition: all 1.2s 0.3s ease-out;
}
header h1 img {
  width: 100%;
}
@media screen and (max-width: 768px) {
  header {
    padding: 20px 0;
  }
  header h1 {
    width: 60%;
  }
}

#top .sp {
  display: none;
}
#top .title_wrap {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
}
#top .title_wrap.show img {
  opacity: 1;
  -webkit-transform: translate(0);
          transform: translate(0);
}
#top .title_wrap.show .p_title:before {
  width: 100%;
}
#top .title_wrap.show .p_title p {
  opacity: 1;
  -webkit-transform: translate(0);
          transform: translate(0);
}
#top .title_wrap:before {
  content: "";
  padding-top: 32.7%;
  display: block;
}
#top .title_wrap img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transform: translate(0, 10px);
          transform: translate(0, 10px);
  -webkit-transition: all 1.6s ease-out;
  transition: all 1.6s ease-out;
}
#top .title_wrap .p_title {
  padding: 20px 60px;
  color: #fff;
  letter-spacing: 0.01em;
  font-size: 32px;
  font-family: "小塚ゴシック Pro", "Kozuka Gothic Pro", sans-serif;
  font-weight: 200;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
#top .title_wrap .p_title p {
  position: relative;
  z-index: 2;
  -webkit-transition: all 1s 0.6s ease-out;
  transition: all 1s 0.6s ease-out;
  opacity: 0;
  -webkit-transform: translate(10px, 0);
          transform: translate(10px, 0);
}
#top .title_wrap .p_title:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  z-index: 0;
  background: -webkit-linear-gradient(left, rgba(226, 0, 0, 0.7) 0%, rgba(226, 2, 2, 0.7) 2%, rgba(226, 97, 97, 0.69) 100%);
  background: -webkit-gradient(linear, left top, right top, from(rgba(226, 0, 0, 0.7)), color-stop(2%, rgba(226, 2, 2, 0.7)), to(rgba(226, 97, 97, 0.69)));
  background: linear-gradient(to right, rgba(226, 0, 0, 0.7) 0%, rgba(226, 2, 2, 0.7) 2%, rgba(226, 97, 97, 0.69) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#b3e20000", endColorstr="#b0e26161",GradientType=1 );
  -webkit-transition: all 0.6s 0.3s ease-out;
  transition: all 0.6s 0.3s ease-out;
}
#top .top_contents {
  max-width: 780px;
  margin: 70px auto 135px;
  width: 85%;
}
#top .top_contents h3 {
  font-size: 26px;
  letter-spacing: 0;
  margin-bottom: 35px;
  opacity: 0;
  -webkit-transform: translate(-20px, 0);
          transform: translate(-20px, 0);
  -webkit-transition: all 0.6s 0.5s ease-out;
  transition: all 0.6s 0.5s ease-out;
}
#top .top_contents h3.show {
  opacity: 1;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
#top .top_contents .com_list {
  width: 100%;
  border-top: 1px solid #000;
  opacity: 0;
  -webkit-transform: translate(-20px, 0);
          transform: translate(-20px, 0);
  -webkit-transition: all 0.6s 1s ease-out;
  transition: all 0.6s 1s ease-out;
}
#top .top_contents .com_list.show {
  opacity: 1;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
#top .top_contents .com_list li {
  border-bottom: 1px solid #000;
  padding: 30px 0;
}
#top .top_contents .com_list li h4 {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.05em;
  line-height: 1.9;
}
#top .top_contents .com_list li .com_list_contents {
  display: inline-block;
  vertical-align: top;
  width: 75%;
  font-size: 14px;
  letter-spacing: 0.05em;
  line-height: 1.9;
}
@media screen and (max-width: 768px) {
  #top .sp {
    display: block;
  }
  #top .title_wrap .p_title {
    padding: 10px 25px 8px;
    font-size: 4.5vw;
  }
  #top .top_contents {
    max-width: inherit;
    margin: 25px auto 30px;
    width: 100%;
    padding: 0 20px;
  }
  #top .top_contents h3 {
    font-size: 5.3vw;
    margin-bottom: 15px;
  }
  #top .top_contents .com_list li {
    padding: 15px 0;
  }
  #top .top_contents .com_list li h4 {
    font-size: 3.5vw;
  }
  #top .top_contents .com_list li .com_list_contents {
    font-size: 3.5vw;
    padding-left: 5%;
  }
}

.group_wrap {
  width: 85%;
  margin: 110px auto 100px;
  text-align: left;
  max-width: 780px;
}
.group_wrap h3 {
  font-size: 22px;
  letter-spacing: 0;
  margin-bottom: 15px;
  margin-top: 50px;
  opacity: 0;
  -webkit-transform: translate(-20px, 0);
          transform: translate(-20px, 0);
  -webkit-transition: all 0.6s 0.5s ease-out;
  transition: all 0.6s 0.5s ease-out;
}
.group_wrap h3.show {
  opacity: 1;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.group_wrap h3:first-of-type {
  margin-top: 0;
}
.group_wrap ul {
  opacity: 0;
  -webkit-transform: translate(-20px, 0);
          transform: translate(-20px, 0);
  -webkit-transition: all 0.6s 1s ease-out;
  transition: all 0.6s 1s ease-out;
}
.group_wrap ul.show {
  opacity: 1;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.group_wrap ul li {
  padding: 20px 0;
  border-top: 1px solid #CDCDCD;
  position: relative;
}
.group_wrap ul li:last-of-type {
  border-bottom: 1px solid #CDCDCD;
}
.group_wrap ul li p {
  font-size: 18px;
  letter-spacing: 0.05em;
  width: 85%;
  line-height: 1.3;
}
.group_wrap ul li a {
  width: 100px;
  height: 26px;
  display: block;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  font-size: 11px;
  line-height: 26px;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .group_wrap {
    width: 100%;
    margin: 45px 0;
    padding: 0 20px;
    max-width: inherit;
  }
  .group_wrap h3 {
    font-size: 18px;
    margin-bottom: 7px;
    margin-top: 30px;
  }
  .group_wrap ul li {
    padding: 15px 0;
  }
  .group_wrap ul li p {
    font-size: 16px;
    width: 80%;
    line-height: 1.5;
  }
  .group_wrap ul li a {
    width: 18%;
    height: 23px;
    line-height: 23px;
  }
}

footer {
  width: 100%;
  background: #98A5B6;
  padding: 25px 0;
  text-align: center;
}
footer .copy {
  font-size: 10px;
  letter-spacing: 0.2em;
  color: #fff;
}

.arrow_scroll {
  width: 50px;
  height: 50px;
  border: 2px solid #333;
  border-radius: 100px;
  position: fixed;
  right: 20px;
  bottom: 40px;
  z-index: 1000;
  -webkit-transform: translate(0, 100px);
          transform: translate(0, 100px);
  display: block;
  background: #fff;
}
.arrow_scroll.show {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.arrow_scroll:before {
  content: "";
  border-right: 13px solid transparent;
  border-bottom: 22px solid #333;
  border-left: 13px solid transparent;
  position: absolute;
  top: 48%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transform-origin: top center;
          transform-origin: top center;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
@media screen and (max-width: 768px) {
  .arrow_scroll {
    display: none;
  }
}